import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
const moment = _rollupMoment || _moment;


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DDD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DDD MMMM YYYY',
  },
};
@Component({
  selector: 'app-payment-payer',
  templateUrl: './payment-payer.component.html',
  styleUrls: ['./payment-payer.component.scss'],
  providers: [DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
})
export class PaymentPayerComponent implements OnInit {
  open: any;
  inputDate:any;
  project_start_date:any;
  submitted=false;
  myForm:any
  constructor(private fb:FormBuilder, private dialog:MatDialog, public dialogRef: MatDialogRef<PaymentPayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.submitted = false;
    this.myForm = this.fb.group({
      project_start_date: ['', [Validators.required]],
    
    })
  
  }
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.project_start_date;
    let month = normalizedMonthAndYear.month()
     
    let year = normalizedMonthAndYear.year()
     
    let date =  (month + 1) + "/" + year
    this.project_start_date = moment(date,"DD/MM/YYYY");
  }

  submit(){
    this.submitted=true
     
    if(this.myForm.valid){
      this.dialogRef.close({ data:this.project_start_date });
    }
    else {

    }
  }

  close(){
    this.dialogRef.close({ event: 'close' });
  }
}
