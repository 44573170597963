<div class="modal-header pt-0 pb-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
        <img src="assets/images/close.png" width="16" height="16">
    </button>
</div>
<form [formGroup]="myForm1">
    <div class="modal-body pt-0">
        <h1 class="text-center mb-4">{{data?.heading}}</h1>
       
            <div class="form-group">
                <input type="text" placeholder="Enter name" formControlName="milestone_name">
                <small *ngIf="submitted && f.milestone_name.invalid">
                    <small *ngIf="f.milestone_name.errors?.required">Milestone name is required</small>
                    <small *ngIf="f.milestone_name.errors?.pattern">Invalid Milestone name</small>
                </small>
            </div>
            <div class="form-group mt-3 position-relative">
                <!-- <input type="date" placeholder="Start date" formControlName="product_delivery_start_date"> -->
                <input type="date" class="dateclass" placeholder="Start date" formControlName="product_delivery_start_date">
                <small *ngIf="submitted && f.product_delivery_start_date.invalid">
                    <small *ngIf="f.product_delivery_start_date.errors?.required">Start date is required</small>
                </small>

                
            </div>
            <div class="form-group mt-3 position-relative">
                <input type="date" placeholder="End date" formControlName="product_delivery_end_date">
                <small *ngIf="submitted && f.product_delivery_end_date.invalid">
                    <small *ngIf="f.product_delivery_end_date.errors?.required">End date is required</small>
                </small>
            </div>
            <div class="mt-3 row">
                <div class="col-md-6">
                    <div>
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-select formControlName="unit_type" placeholder="Enter unit Type">
                                <mat-option value="kg">Kg</mat-option>
                                <mat-option value="gram">Gram</mat-option>
                                <!-- <mat-option value="meter">Meter</mat-option>
                                <mat-option value="ton">Ton</mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <small *ngIf="submitted && f.unit_type.invalid">
                        <small *ngIf="f.unit_type.errors?.required">Unit type is required</small>
                    </small>
                    
                </div>
                <div class="col-md-6">
                    <input type="text" placeholder="Enter number of units" formControlName="number_of_unit">
                    <small *ngIf="submitted && f.number_of_unit.invalid">
                        <small *ngIf="f.number_of_unit.errors?.required">Number of units is required</small>
                        <small *ngIf="f.number_of_unit.errors?.pattern">Invalid number of units</small>
                    </small>
                </div>
            </div>
      

    </div>
    <div class="modal-footer d-flex justify-content-between pt-4">
        <button type="button" class="btn footer-btn save-btn me-1"
            (click)="onSubmit(myForm1.value)">Add</button>
        <button type="button" class="btn footer-btn cancel-btn ms-1"
            [mat-dialog-close]="false">{{data?.cancelBtn}}</button>
    </div>
</form>