import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { ReadyDispatchComponent } from '../ready-dispatch/ready-dispatch.component';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import { ValidationsService } from 'src/app/services/validators/validator';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-add-purchase-manufacture-milestone',
  templateUrl: './add-purchase-manufacture-milestone.component.html',
  styleUrls: ['./add-purchase-manufacture-milestone.component.scss'],
  providers: [DatePipe,
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    // },

    // {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
})
export class AddPurchaseManufactureMilestoneComponent implements OnInit {

  open: any;
  files: any = [];
  myForm1: FormGroup;
  submitted = false;
  formData: any;
  ipAddress: any;
  //files: any = [];
  images: any = [];
  videos: any = [];
  Orderversion:any
  pdf: any = [];
  media: any = [];
  version:any;
  maxVersion:any;
  companyId: any;
  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddPurchaseManufactureMilestoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private service: ApiServicesService,
    private toastr: ToastrService,public validators:ValidationsService,private storageService:LocalStorageProvider) { }

  ngOnInit(): void {
    // debugger

    this.formData = this.data.data;
    this.version = this.data.version;
    this.maxVersion = Math.max(...this.version.map((el:any) => el.version));
     
    // let companyDetail:any;
    // let data = sessionStorage.getItem('currentUser');
    // companyDetail = data;
    // let finalData = JSON.parse(companyDetail);
    // let dataUser = finalData.result?finalData.result:finalData;
     
    
    this.companyId = this.storageService.getItem('companyId');
     
    
    
    
    this.formData.Media.forEach((el:any)=>{
      let file = {
        media_type: el.media_type,
        url: el.url
      }
      this.files.push(file)
    })
    // this.formData.Media.forEach((element: any) => {
    //   // debugger
    //   let file = {
    //     'media_type': element.media_type,
    //     'url': element.url
    //   }
    //   if (element.media_type.toLowerCase().includes('image/png')) {
    //     this.images.push(file)
    //     this.media = this.images;
    //   }
    //   else if (element.media_type.toLowerCase().includes('video')) {
    //     this.videos.push(file)
    //     this.media = this.videos;
    //   }
    //   else {
    //     this.pdf.push(file)
    //     this.media = this.videos;
    //   }
    // });
    // this.files = [...this.images, ...this.videos, ...this.pdf];
    //this.files.push//=this.formData.Media;
    this.createForm();
     
  }
  get f() { return this.myForm1.controls; }
  /**createForm*/
  createForm() {
    this.myForm1 = this.fb.group({

      milestone_name: [this.formData.ProductDeliveryMilestones[0].milestone_name],
      product_delivery_start_date: [this.formData.ProductDeliveryMilestones[0].product_delivery_start_date],
      product_delivery_end_date: [this.formData.ProductDeliveryMilestones[0].product_delivery_end_date],
      unit_type: [this.formData.ProductDeliveryMilestones[0].unit_type],
      number_of_unit: [this.formData.ProductDeliveryMilestones[0].number_of_unit],
      payment_recieved_date: [this.formData.ProductDeliveryMilestones[0].payment_recieved_date],
      manufacturing_milestone_name: ["", [Validators.required, Validators.pattern(this.validators.regex.milestoneNamePattern)]],
      manufacturing_start_date: ["", Validators.required],
      manufacturing_end_date: ["", Validators.required],
      manufacturing_number_of_unit: ["", [Validators.required, Validators.pattern(this.validators.regex.numberPattern)]],
      manufacturing_unit_type: ["", Validators.required],
      manufacturing_payment_recieved_date: [Date.now()],
      email: [this.formData.email],
      contact_no: [this.formData.contact_no],
      address: [this.formData.address],
      category1_id: [this.formData.category1_id],
      category2_id: [this.formData.category2_id],
      industry_id: [this.formData.industry_id],
      brand_id: [this.formData.brand_id,],
      hsn_code: [this.formData.hsn_code],
      tax_type: [this.formData.tax_type],
      tax_percentage: [this.formData.tax_percentage],
      order_created_by: [this.formData.order_created_by],
      discount_percentage: [this.formData.discount_percentage],
      discount_type: [this.formData.discount_type],
      amount_recieved: [this.formData.amount_recieved],
      final_payment_date: [this.formData.final_payment_date],
      no_of_unit_ordered: [this.formData.no_of_unit_ordered],
      version: [this.formData.version],
      price: [this.formData.price],
      media: [this.files],
      initial_id: [this.formData.initial_id?this.formData.initial_id:this.formData.id],
      type: [this.formData.type],
      category3_id: [this.formData.category3_id,],
      category4_id: [this.formData.category4_id,],
      company_id: [this.companyId],
      product_type: [this.formData.product_type],
      product_sub_type: [this.formData.product_sub_type],
      custom_fields: [[]],
      manufacturing_id: this.formData.auth_id
    })

  }

  readyDispatch() {
    const dialogRef = this.dialog.open(ReadyDispatchComponent, {
      maxHeight: '100vh',
      width: '421px',
      panelClass: 'dispatch',
      data: {
        heading: 'Ready to dispatch?',
        report: 'Delay',
        cancel: 'Yes'
      }
    })
  }


  onSubmit(post: any) {
    

    // delete this.myForm.value.company_id;
    // if (this.myForm1.value.product_type === null) {
    //   delete this.myForm1.value.product_type;
    // }
    // if (this.myForm1.value.product_sub_type === null) {
    //   delete this.myForm1.value.product_sub_type;
    // }
    // if (this.myForm1.value.category3_id === null) {
    //   delete this.myForm1.value.category3_id;
    // }
    // if (this.myForm1.value.category4_id === null) {
    //   delete this.myForm1.value.category4_id;
    // }
    // if (this.myForm1.value.company_name === null) {
    //   delete this.myForm1.value.company_name;
    // }
    // if (this.myForm1.value.company_id === null) {
    //   delete this.myForm1.value.company_id;
    // }
    let Addedversion = parseFloat(this.maxVersion) + 0.1;
    
    this.myForm1.value['version'] = parseFloat(Addedversion.toFixed(1));
    
    
    if(this.myForm1.value['product_type']){
      delete this.myForm1.value['category3_id'];
    }
    if(this.myForm1.value['product_sub_type']){
      delete this.myForm1.value['category4_id'];
    }
    // this.Orderversion = parseFloat(Addedversion.toFixed(1));

    // if (this.myForm1.value.manufacturing_id === null) {
    //  post.manufacturing_id=this.formData.id;
    // }else{
    //   post.manufacturing_id==this.formData.ProductManufacturingMilestones[0].id
    // }
    // if (this.myForm1.value.initial_id === null) {
    //   post.initial_id=this.formData.id;
    //  }else{
    //    post.initial_id==this.formData.initial_id;
    //  }
    //  if (this.myForm1.value.manufacturing_payment_recieved_date === null) {
    //   post.manufacturing_payment_recieved_date=new Date;
    //  }else{
    //    post.manufacturing_payment_recieved_date==this.formData.ProductManufacturingMilestones[0].manufacturing_payment_recieved_date;
    //  }
    //  post.version=this.Orderversion;
    // debugger
    // let firtsReq = this.myForm1.value;
    //  
    if (this.myForm1.valid) {
      
      this.service.post(this.myForm1.value,API_ROUTES.PurchaseOrder.updatePurchaseOrder,{}).pipe().subscribe((res => {
        if (res.success) {
          // debugger
          this.dialogRef.close(true);
          this.toastr.success(res.message);
        }
        else {
          this.toastr.error(res.message);
        }
      }))
    }else {
      this.submitted = true;
    }
  }

  isNumberKey(evt: any) {
     
    //var e = evt || window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }


}
