<div class="modal-header pb-0">
    <img src="assets/images/close.png" alt="" class="cursor" [mat-dialog-close]="false">
</div>
<div class="modal-body pt-0">
    <h3 class="mb-5 ms-3">Filter by Milestone</h3>
   <form [formGroup]="filterForm">
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="d-flex justify-content-between align-items-center mt-md-4 mt-2" *ngFor="let sort of sort">
                <label class="container" (change)="onCheckboxChange($event)">
                    <input type="checkbox"  [value]="sort.label" [checked]="sort.isSelected">
                    <span class="checkmark"></span>
                    {{sort.label}}
                  </label>
            </div>
            <div class="mt-4">
                <div class="mt-3 d-flex flex-column">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-select placeholder="Select Company name" formControlName="company_name">
                            <mat-option  *ngFor="let companyItem of companiesList" value="companyItem.company_name">{{companyItem.company_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mt-3 d-flex flex-column phonedetails">
                    <mat-form-field appearance="fill">
                        <mat-select placeholder="Select Category" formControlName="industry_id" (selectionChange)="getIndustry($event)" >
                            <mat-option *ngFor="let business of business" [value]="business.id">
                                {{business.industry_name}}</mat-option>
                                                    </mat-select>
                    </mat-form-field>
                </div>
                <div class="mt-3 d-flex flex-column phonedetails">
                    <mat-form-field appearance="fill">
                        <mat-select placeholder="Select Product name" formControlName="category1_id">
                            <mat-option *ngFor="let industry of industry" [value]="industry.id">
                                {{industry.title}}</mat-option>
                                                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
                <button class="btn apply-btn" (click)="sortBy()">
                    Apply <img src="assets/images/login.svg">
                </button>
    </div>
   </form>
   
</div>

