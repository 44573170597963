

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageProvider } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanLoad {
  private otpVerified = false;
  // private loggedIn = new BehaviorSubject<boolean>(false);
  constructor(
    private Http: HttpClient,
    private storageService: LocalStorageProvider,
    private router: Router,
  ) { }


  canLoad(route: Route, segments: UrlSegment[]): boolean {
    let token = this.storageService.getItem('access_token');
    // let cUser = this.storageService.getItem('CurrentUser');
    let guestUser = this.storageService.getItem("guestUser");
    const currentRoute = window.location.href;
    if (currentRoute.includes('business')) {
      this.router.navigate([''])
    }

    if (token || guestUser) {
      return true;
    }
    this.router.navigate([''])
    return false;
  }

  // login(body:Object): Observable<any> {
  //   return this.Http.post<any>(``,body).pipe(
  //     catchError(err => {

  //       // err.statusText? this.toastr.error(err.statusText): this.toastr.error(err.message);
  //       return err;
  //     }),
  //     map(resp => resp),
  //   );
  // }
  // get isLoggedIn() {
  //   let token = this.storageService.getItem('access_token');
  //   let cUser = this.storageService.getItem('CurrentUser')
  //   if (token != undefined && token != null && cUser != undefined && cUser != null) {
  //     this.loggedIn.next(true);
  //   }
  //   return this.loggedIn.asObservable();
  // }
  logout() {
    this.storageService.clearAll();
    // this.loggedIn.next(false);
    this.router.navigate(['']);
  }
  setOtpVerified(status: boolean) {
    this.otpVerified = status;
  }

  // Check if OTP is verified
  isOtpVerified(): boolean {
    return this.otpVerified;
  }

}