<div class="d-flex align-items-center justify-content-between modal-header pb-0">
    <h2 class="h2">Likes</h2>
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
        <img src="assets/images/close.png" width="16" height="16">
      </button>
</div>
<div class="p-3 m">
    <div *ngFor="let likes of likesList">
    <div class="c d-flex align-items-end items mt-3">
    <img class="rounded-circle" onError="this.src='assets/images/Profile_1.svg'" [src]="likes?.Auth?.CompanyInfo?.profile_pic?likes?.Auth?.CompanyInfo?.profile_pic:'assets/images/Profile_1.svg'" height="40px" width="40px">
    <h3 class="mx-3 title">{{likes?.Auth?.CompanyInfo?.company_name}}</h3>
    </div>
    <hr>
    </div>
</div>
