import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-sales-filter',
  templateUrl: './sales-filter.component.html',
  styleUrls: ['./sales-filter.component.scss']
})
export class SalesFilterComponent implements OnInit {
  open: any;
  companiesList:any;
  businessActivityList:any;
  industry: any;
  business: any;
  filterForm:FormGroup;

  constructor( public dialogRef: MatDialogRef<SalesFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: ApiServicesService,private fb:FormBuilder) { }
  ngOnInit(): void {
    this.getCompanyList();
    this.businessActivity();
    this.createFilterForm()
  }

  sort=[
    {
      label:'Price Low to High',
      value:'price-l2h',
      isSelected:false
    },
    {
      label:'Price High to Low',
      value:'price-h2l',
      isSelected:false
    },
    {
      label:'Most recent',
      value:'default',
      isSelected:false
    },
    // {
    //   label:' Date Posted: Most recent on last',
    //   value:'',
    //   isSelected:false
    // },
  ]
  getCompanyList() {
    // debugger
    this.service.get({}, `${API_ROUTES.SalesOrder.getCompanyList}`).pipe().subscribe((res) => {
      if (res.success) {
        this.companiesList = res.result.companyDetail;
      } else {

      }
    }
    )
  }
  businessActivity(){
    this.service.get({},`${API_ROUTES.Marketplace.businessActivity}`).pipe().subscribe((res)=>{
      this.business = res.result;
       
    })
  }

  getIndustry(event:any){
    if(event.value != "0"){
      this.service.get({industry_id:event.value},`${API_ROUTES.Marketplace.catagory_1}`).pipe().subscribe((res)=>{
        this.industry = res.result.rows;
         
      })
    }
    
  }
  onCheckboxChange(item:any) {
    this.sort.forEach(val => {
      if (val.label == item.target.value){ 
        val.isSelected = !val.isSelected;
       
      }
      else {
        val.isSelected = false;
      }
    });
  }


  createFilterForm(){
    this.filterForm = this.fb.group({
      company_name: [this.data?.filterBy?.company_name? this.data.filterBy.company_name: '0'],
      industry_id: [this.data?.filterBy?.industry_id? this.data.filterBy.industry_id: '0'],
      category1_id: [this.data?.filterBy?.category1_id? this.data.filterBy.category1_id:'0'],
     
    })
  }
  patchData(){
    if(this.data.sortBy){
      for(let i=0;i<this.sort.length;i++){
        if(this.sort[i].value == this.data.sortBy){
          this.sort[i].isSelected = true;
        }
      }
    }
    if(this.data.sortBy){
     
      if(this.data.sortBy.industry_id && this.data.sortBy.industry_id!= "0"){
        // this.filterForm.value['industry_id'] = this.data.filterBy.industry_id
        let ev = {
            value : this.data.sortBy.industry_id
          
        }
        this.getIndustry(ev);
      }
      
     
    }
  }
 

  sortBy(){
    this.dialogRef.close( {result:true,sortdata: this.sort });
  }
}
