<div class="modal-header p-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
      <img src="assets/images/close.png" width="16" height="16">
    </button>
</div>
<div class="modal-body py-0">
    <img *ngIf="data?.img" [src]=data?.img width="53" height="53">
    <h1 class="pt-3 px-3">{{data?.heading}}</h1>
    <!-- <p>{{data?.para}}</p> -->
</div>
<div class="modal-footer d-flex justify-content-between mt-3">
    <button type="button" class="btn cancel me-md-2 me-0" [mat-dialog-close]="false">{{data?.cancel}}</button>
    <button type="button" class="btn report ms-md-2 ms-0 mt-md-0 mt-3" [mat-dialog-close]="true">{{data?.report}}</button>
</div>

