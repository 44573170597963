import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { SignInService } from 'src/app/services/sign-in.service';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  open: any;
  profileDetails: any;

  constructor(private router: Router, private dialog: MatDialog, private storageService: LocalStorageProvider,
    private service: ApiServicesService, public dialogRef: MatDialogRef<LogoutComponent>, private afAuth: AngularFireAuth,
    private signInService: SignInService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.getProfile();
    this.profileDetails = this.data.data
  }
  logout() {
    if (this.profileDetails && (this.profileDetails.Auth.google_id || this.profileDetails.Auth.facebook_id || this.profileDetails.Auth.linked_in_id)) {
      this.signOut();
    }
    else {
      // this.router.navigate([''])
      this.logoutAPI();
    }

  }

  // getProfile(){
  //   this.service.get({},`${API_ROUTES.More.moreDetails}`).pipe().subscribe((res)=>{
  //     this.profileDetails = res.result[0];
  //   })
  // }

  async signOut() {
    await this.afAuth.signOut();
    this.logoutAPI();
  }

  logoutAPI() {
    let params = {
      device_id: sessionStorage.getItem('device_Id')?.toString()
    }
    this.service.delete(params, `${API_ROUTES.Account.logout}`).pipe().subscribe((res) => {
      if (res.success) {
        localStorage.removeItem('access_token');
        // localStorage.removeItem('isLoggedIn');
        this.storageService.clearAll();
        window.location.reload();
        this.router.navigate([''])
      }
    }, (error) => {
      localStorage.removeItem('access_token');
      this.storageService.clearAll();
      window.location.reload();
      this.router.navigate([''])
    })
  }
}
