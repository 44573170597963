import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs'
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
@Injectable()
export class MessagingService {
currentMessage = new BehaviorSubject(null);
constructor(private angularFireMessaging: AngularFireMessaging,private storageService:LocalStorageProvider) {
    this.angularFireMessaging.messages.subscribe(
        (_messaging: any) => {
          _messaging.onMessage = _messaging.onMessage.bind(_messaging);
          _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
          // messaging.onBackgroundMessage = messaging.onBackgroundMessage.bind(_messaging)
        }
      )
}
requestPermission() {
this.angularFireMessaging.requestToken.subscribe(
(token:any) => {
if(token != null){
token = token
}
else {
    token = "EMPTY"
}

sessionStorage.setItem('deviceToken',token)
},
(err) => {
}
);
}
receiveMessage() {
this.angularFireMessaging.messages.subscribe(
(payload:any) => {
// this.currentMessage.next(payload);
})
// this.angularFireMessaging.onBackgroundMessage(payload=>{
//      
    
// })
this.angularFireMessaging.onMessage(payload=>{
    
})
}
}