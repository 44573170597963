import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OrderDetailsComponent } from 'src/app/business/business-pages/customers/order-details/order-details.component';
import { AppConstant } from 'src/app/core/_constants/app.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { DelayComponent } from '../delay/delay.component';
// import { OrderDispatchComponent } from '../order-dispatch/order-dispatch.component';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ready-dispatch',
  templateUrl: './ready-dispatch.component.html',
  styleUrls: ['./ready-dispatch.component.scss']
})
export class ReadyDispatchComponent implements OnInit {
  open: any;
  orderId: any
  submitted = false;
  myForm: FormGroup;
  selectedDeliverMileStone: any;
  id: any;
list:any=[{
value:'mile',
mile_delivered:'mile'
}]
  milestoneList: any;
  constructor(private fb:FormBuilder, private dialog: MatDialog, public dialogRef: MatDialogRef<ReadyDispatchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: ApiServicesService,private router:Router,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
this.getCompanyList()
    this.orderId=this.data.OrderId
    this.createForm();
  }
  delay() {
    const dialogRef = this.dialog.open(DelayComponent, {
      maxHeight: '100vh',
      width: '421px',
      data: {
        heading: 'Ready to dispatch?',
        report: 'Delay',
        cancel: 'Yes'
      }
    })
  }

  // orderDispatched() {
  //   const dialogRef = this.dialog.open(OrderDispatchComponent, {
  //     maxHeight: '100vh',
  //     width: '465px',
  //     panelClass: 'products',
  //     data: {
  //       img: 'assets/images/Completed_check.svg',
  //       heading: 'Your Sales Order has been dispatched',
  //       title: 'Please check your inbox and click in the recieved link to reset a password',
  //       btn: 'View delivery challan'
  //     }
  //   })
  //   dialogRef.afterClosed().subscribe(confirm => {
  //     // debugger
  //     if (confirm !== undefined && confirm !== null && confirm) {
  //       // this.onSubmit();
  //     }
  //   });
  // }
  createForm() {
    this.submitted = false;
    this.myForm = this.fb.group({
      // id: this.orderId,
      mile_delivered: ["", [Validators.required]],
    
    })
  
  }
  get f() { return this.myForm.controls; }

  getCompanyList() {
    let dataToPost={
      parent_id:this.data.OrderId
    }
    this.service.get(dataToPost, `${API_ROUTES.PurchaseOrder.Milestonelist}`).pipe().subscribe((res) => {
      
     this.milestoneList = res.result;
    }
    )
  }

  selectedMilestone(ev:any){
     
     
     
    
    
    
  }

  onSubmit() {
    this.submitted = true;
    if(this.myForm.valid){
      let findObj = this.milestoneList.find((el:any)=> el.order_id == this.myForm.value['mile_delivered']);
      let dataToPost = {
        order_id: this.myForm.value['mile_delivered'],
        mile_delivered:findObj.milestone_name
      }
      this.service.put(dataToPost,{},API_ROUTES.PurchaseOrder.dispatch).pipe().subscribe((res)=>{
         
        this.dialogRef.close(true);
        
      },
      (error)=>{
        this.toastr.error(error.message);
      })
    }
    // let data={
    //   mile_delivered:this.data.mile_delivered
    // }
    // // debugger
    // this.submitted = true;
    // this.service.put(data,{}, API_ROUTES.PurchaseOrder.dispatch+'?id='+this.orderId).pipe().subscribe((res => {
    //   if (this.myForm.valid) {
    //     this.dialogRef.close(true);
    //     this.toastr.success(res.message, AppConstant.success, { timeOut: 5000 });
    //   }
    //   else {
    //     this.toastr.error(res.message, AppConstant.error, { timeOut: 5000 });
    //   }
    // })
    // )
  }
}
