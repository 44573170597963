<div class="modal-header pt-0 pb-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
        <img src="assets/images/close.png" width="16" height="16">
    </button>
</div>
<form [formGroup]="myForm1">
    <div class="modal-body pt-0">
        <h1 class="text-center mb-4">{{data?.heading}}</h1>
       
            <div class="form-group">
                <input class="locationdata" type="text" placeholder="Enter name" formControlName="manufacturing_milestone_name">
                <small *ngIf="submitted && f.manufacturing_milestone_name.invalid">
                    <small *ngIf="f.manufacturing_milestone_name.errors?.required">Milestone name is required</small>
                    <small *ngIf="f.manufacturing_milestone_name.errors?.pattern">Invalid Milestone name</small>
                </small>
            </div>
            <!-- <div class="form-group mt-3 position-relative">
                <mat-form-field appearance="fill" style="width:100%" class="mt-2">
                    <input matInput [matDatepicker]="endDate" placeholder="Start date"  mat-raised-button
                    (click)="endDate.open()" readonly  formControlName="manufacturing_start_date">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate
                    panelClass="example-month-picker"
                    ></mat-datepicker>
                   
                </mat-form-field>
                <small *ngIf="submitted && f.manufacturing_start_date.invalid">
                    <small *ngIf="f.manufacturing_start_date.errors?.required">Start date is required</small>
                </small>
            </div>
            <div class="form-group mt-3 position-relative">
                <input type="date" placeholder="End date" formControlName="manufacturing_end_date">
                <small *ngIf="submitted && f.manufacturing_end_date.invalid">
                    <small *ngIf="f.manufacturing_end_date.errors?.required">End date is required</small>
                </small>
            </div> -->
            <div class="form-group mt-3 position-relative">
                <mat-form-field appearance="fill" style="width:100%" class="mt-2 cursor">
                    <input matInput [matDatepicker]="startDate" placeholder="Start Date"   
                       formControlName="manufacturing_start_date" (click)="startDate.open()" readonly />
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate
                                    panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
                <!-- <input type="date" placeholder="Start date" formControlName="manufacturing_start_date"> -->
                <small *ngIf="submitted && f.manufacturing_start_date.invalid">
                    <small *ngIf="f.manufacturing_start_date.errors?.required">Start date is required</small>
                </small>
            </div>
            <div class="form-group mt-3 position-relative">
                <mat-form-field appearance="fill" style="width:100%" class="mt-2 cursor">
                    <input matInput [matDatepicker]="endDate" placeholder="End Date"   
                       formControlName="manufacturing_end_date" (click)="endDate.open()" readonly />
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate
                                    panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
                <!-- <input type="date" placeholder="End date" formControlName="manufacturing_end_date"> -->
                <small *ngIf="submitted && f.manufacturing_end_date.invalid">
                    <small *ngIf="f.manufacturing_end_date.errors?.required">End date is required</small>
                </small>
            </div>
            <div class="mt-3 row">
                <div class="col-md-6">
                    <div>
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-select formControlName="manufacturing_unit_type" placeholder="Enter unit type">
                                <mat-option value="kg">Kg</mat-option>
                                <mat-option value="gram">Gram</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <small *ngIf="submitted && f.manufacturing_unit_type.invalid">
                        <small *ngIf="f.manufacturing_unit_type.errors?.required">Unit type is required</small>
                    </small>
                </div>
                <div class="col-md-6">
                    <input class="locationdata" type="text" placeholder="Enter number of units" formControlName="manufacturing_number_of_unit" maxlength="9" (click)="isNumberKey($event)">
                    <small *ngIf="submitted && f.manufacturing_number_of_unit.invalid">
                        <small *ngIf="f.manufacturing_number_of_unit.errors?.required">Number of units is required</small>
                        <small *ngIf="f.manufacturing_number_of_unit.errors?.pattern">Invalid number of units</small>
                    </small>
                </div>
                
                
            </div>
      

    </div>
    <div class="modal-footer d-flex justify-content-between pt-4">
        <button type="button" class="btn footer-btn save-btn me-1"
            (click)="onSubmit(myForm1.value)">Add</button>
        <button type="button" class="btn footer-btn cancel-btn ms-1"
        [mat-dialog-close]="false">{{data?.cancelBtn}}</button>
    </div>
</form>