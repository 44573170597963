<div class="modal-header pb-0 p-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
       <img src="assets/images/close 1.svg" class="cl" width="40px" height="40px">
    </button>
</div>
<div class="modal-body pt-0">
    <h1 class="pt-2">Add New</h1>
        <p>Choose one option that you would 
            like to continue with </p>
            <img src="assets/images/Website_Content.svg" class="c">
</div>
<!-- <div class="modal-footer d-block mt-3">
    <div class="text-center mb-3 me-2 d-flex align-items-center" [routerLink]="['/main/home/add-new-requirementtab']" [mat-dialog-close]="false">
        <img src="assets/images/requirement.svg">
        <p class="mb-0 ms-3"> Requirement</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center" [routerLink]="['/main/home/create-new-post']" [queryParams]="{type: 'new'}" [mat-dialog-close]="false">
        <img src="assets/images/post_1.svg">
        <p class="mb-0 ms-3"  > Post</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="['/main/profile/newFeature']" [mat-dialog-close]="false">
        <img src="assets/images/feature.svg">
        <p class="mb-0 ms-3"> Featured Product</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="['/main/profile/newProjects']" [mat-dialog-close]="false">
        <img src="assets/images/projects.svg">
        <p class="mb-0 ms-3"> Project</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="['/main/profile/newMachine']" [mat-dialog-close]="false">
        <img src="assets/images/machine_1.svg">
        <p class="mb-0 ms-3"> Instrument / Machine</p>
    </div>
</div> -->

<div class="modal-footer d-block mt-3">
    <div class="text-center mb-3 me-2 d-flex align-items-center" [routerLink]="[selectRequirement]" [mat-dialog-close]="false">
        <img src="assets/images/requirement.svg">
        <p class="mb-0 ms-3"> Requirement</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center" [routerLink]="[addPost]" [queryParams]="{type: 'new'}" [mat-dialog-close]="false">
        <img src="assets/images/post_1.svg">
        <p class="mb-0 ms-3"  > Post</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="[addFeaturedProduct]" [mat-dialog-close]="false">
        <img src="assets/images/feature.svg">
        <p class="mb-0 ms-3"> Featured Product</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="[addProject]" [mat-dialog-close]="false">
        <img src="assets/images/projects.svg">
        <p class="mb-0 ms-3"> Project</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="[addCapacity]" [mat-dialog-close]="false">
        <img src="assets/images/machine_1.svg">
        <p class="mb-0 ms-3"> Capacity/Machine</p>
    </div>
    <!-- <div class="text-center me-2 d-flex align-items-center" [mat-dialog-close]="false">
        <img src="assets/images/feature.svg">
        <p class="mb-0 ms-3"> Marketplace Products</p>
    </div> -->
</div>