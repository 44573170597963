import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { EditPopupComponent } from '../edit-popup/edit-popup.component';
import { ReadyDispatchComponent } from '../ready-dispatch/ready-dispatch.component';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { HttpClient  } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { DatePipe } from '@angular/common';
import { ValidationsService } from 'src/app/services/validators/validator';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DDDD MMMM YYYY',
  },
};
@Component({
  selector: 'app-add-manufacture-product',
  templateUrl: './add-manufacture-product.component.html',
  styleUrls: ['./add-manufacture-product.component.scss'],
  providers: [DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
})
export class AddManufactureProductComponent implements OnInit {
  open: any;
  files: any = [];
  myForm: FormGroup;
  submitted=false;
  formData:any;
  ipAddress:any;
  Orderversion:any;
  version: any;
  maxVersion: any;
  companyId: any;
  constructor(private dialog :MatDialog,public validators:ValidationsService,
     public dialogRef: MatDialogRef<AddManufactureProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private fb: FormBuilder, private service: ApiServicesService,
    private toastr: ToastrService,private http:HttpClient,private storageService:LocalStorageProvider) { }

  ngOnInit(): void {
    // debugger
    this.formData=this.data.data;
    this.version = this.data.version;
    this.maxVersion = Math.max(...this.version.map((el:any) => el.version));
     
    
    this.files=this.formData.Media;
    // let data = sessionStorage.getItem('currentUser');
    // let companyDetail:any
    // companyDetail = data;
    // let finalData = JSON.parse(companyDetail);
    // let dataUser = finalData.result?finalData.result:finalData;
     
    
    this.companyId = this.storageService.getItem('companyId');
     
    this.createForm();
     
    this.minDateToFinish.subscribe((r: string | number | Date) => {
      this.minDate = new Date(r);
    })
    this.endminDateToFinish.subscribe((r: string | number | Date) => {
      this.minstartDate = new Date(r);
    })
  }

  minDateToFinish = new Subject<string>();
  endminDateToFinish = new Subject<string>();
  showmsg:boolean=false
  minDate:any;
  minstartDate:any;

  dateChange(e:any) {
    this.minDateToFinish.next(e.value.toString());
   
  }
enddateChange(e:any){
  this.endminDateToFinish.next(e.value.toString());
  
}

  get f() { return this.myForm.controls; }
    /**createForm*/
    createForm() {
      this.myForm = this.fb.group({
        company_name: this.formData.company_name,
        email: [this.formData.email],
        contact_no: [this.formData.contact_no],
        address: [this.formData.address],
        category1_id: [this.formData.category1_id],
        category2_id: [this.formData.category2_id],
        category3_id: [this.formData.category3_id],
        category4_id: [this.formData.category4_id],
        industry_id: [this.formData.industry_id],
        brand_id: [this.formData.brand_id],
        product_type: [this.formData.product_type],
        product_sub_type: [this.formData.product_sub_type],
        hsn_code: [this.formData.hsn_code],
        tax_type: [this.formData.tax_type],
        tax_percentage: [this.formData.tax_percentage],
        product_delivery_start_date: [null, [Validators.required]],
        product_delivery_end_date: [null, [Validators.required]],
        number_of_unit: [null, [Validators.required, Validators.pattern(this.validators.regex.numberPattern)]],
        unit_type: [null, [Validators.required]],
        milestone_name: [null, [Validators.required, Validators.pattern(this.validators.regex.milestoneNamePattern)]],
        payment_recieved_date: [Date.now()],
        order_created_by: [this.formData.order_created_by],
        discount_percentage: [this.formData.discount_percentage],
        discount_type: [this.formData.discount_type],
        amount_recieved: [this.formData.amount_recieved],
        final_payment_date: [this.formData.final_payment_date],
        no_of_unit_ordered: [this.formData.no_of_unit_ordered],
        type: [this.formData.type],
        initial_id: [this.formData.initial_id],
        parent_id: [this.formData.initial_id],
        company_id: [this.formData.CompanyInfo.id],
        version: [null],
        price: [this.formData.price],
        media: [this.files],
        //custom_fields: [null],
      })
  
    }
   
  readyDispatch(){
    const dialogRef=this.dialog.open(ReadyDispatchComponent,{
      maxHeight: '100vh',
      width:'421px',
      panelClass:'dispatch',
      data: {
        heading:'Ready to dispatch?',
        report:'Delay',
        cancel:'Yes'
      }
    })
  }

  
  onSubmit(post:any) {
    // this.submitted = true;

   // delete this.myForm.value.company_id;
  //  if (this.myForm.value.product_type=== null) {
  //   delete this.myForm.value.product_type;
  // }
  // if (this.myForm.value.product_sub_type=== null) {
  //   delete this.myForm.value.product_sub_type;
  // }
  // if (this.myForm.value.category3_id=== null) {
  //   delete this.myForm.value.category3_id;
  // }
  // if (this.myForm.value.category4_id=== null) {
  //   delete this.myForm.value.category4_id;
  // }
  // if (this.myForm.value.company_name=== null) {
  //   delete this.myForm.value.company_name;
  // }
  // if (this.myForm.value.company_id=== null) {
  //   delete this.myForm.value.company_id;
  // }
  if(this.myForm.value['product_type']){
    this.myForm.removeControl('category3_id');
  }
  if(this.myForm.value['product_sub_type']){
    this.myForm.removeControl('category4_id');
  }
    // debugger
    let Addedversion = parseFloat(this.maxVersion) + 0.1;
    this.myForm.value['version'] = parseFloat(Addedversion.toFixed(1));
    let firtsReq = this.myForm.value;
       
      if (this.myForm.valid) {
        this.service.post(this.myForm.value, API_ROUTES.PurchaseOrder.updatePurchaseOrder, {}).pipe().subscribe((res => {
          if (res.success) {
            this.dialogRef.close(true);
            this.toastr.success(res.message);
          }
          else{
            this.toastr.error(res.message);
          }
        }))
      }else {
        this.submitted = true;
      }
  }

  isNumberKey(evt: any) {
     
    //var e = evt || window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

}
