import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmPasswordValidator } from 'src/app/auth/signup/confirm-password.validator';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { ValidationsService } from 'src/app/services/validators/validator';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  createPassword:FormGroup;
  submitted:boolean=false;
  confirmpassword: string = 'password';
  confirmshow: boolean = false;
  password: string = 'password';
  show: boolean = false;
  constructor(private fb:FormBuilder,public dialogRef: MatDialogRef<CreatePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,private service:ApiServicesService,private router:Router,
    public validators:ValidationsService) { }

  ngOnInit(): void {
    this.createPassword = this.fb.group({ 
      password:['',[Validators.required,Validators.pattern(this.validators.regex.passwordPattern)]],
      confirmPassword: ['',[Validators.required]],
      },
      {
        validator: ConfirmPasswordValidator("password", "confirmPassword")
      }
      );
  }

  get f() {
    return this.createPassword.controls;
  }

  onSubmit(){
    this.submitted = true;
    if(this.createPassword.valid){
      let body = {
        password:this.createPassword.value['password']
      }
      this.service.post(body,API_ROUTES.Account.createPassword,{}).pipe().subscribe((res)=>{
        if(res.success){
          this.dialogRef.close(true);
          this.router.navigateByUrl('/' + RouteConstant.main + '/' + RouteConstant.home);
        }
        
      })
      
    }
  }

  confirm(){
    if (this.confirmpassword === 'password') {
      this.confirmpassword = 'text';
      this.confirmshow = true;
    } else {
      this.confirmpassword = 'password';
      this.confirmshow = false;
    } 
  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

}
