<div class="highlights mt-3" *ngIf="testimonialList?.length > 0">
    <span>Testimonial</span>
    <div class="testimonal-user">
        <div class="d-flex align-items-center justify-content-between mt-2" *ngFor="let item of testimonialList">
            <div class="d-flex align-items-center">
                <img class="rounded-circle uploadedImg" height="30px" width="30px" [src]="item?.Auth?.CompanyInfo?.profile_pic?item?.Auth?.CompanyInfo?.profile_pic:'assets/images/Profile_1.svg'">
            <div class="mx-3 testimonialUser">{{item?.Auth?.CompanyInfo?.company_name}}</div>
            <span class="user me-4">{{item?.description}}</span>
            </div>
            <div class="d-flex rating">
                <svg class="me-1"  width="20px"
                height="20px" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z" fill="#FFFFFF"/>
                    </svg>
                <!-- <img src="assets/images/star.svg"class="me-1"  width="20px"
                height="20px"> -->
            <!-- <img src="assets/images/testimonal.svg"> -->
            <span class="">{{item?.star_rating}}</span>
            </div>
           
        </div>
        
        <!-- <p class="user-details">{{updateUserInfo.comment}}
        </p> -->
    </div>
</div>