<div class="modal-header pb-0 pt-0">
    <button class="button-close mb-2" mat-button (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
        </svg>
    </button>
</div>
<form [formGroup]="myForm">
    <div class="modal-body pt-0">
        <img src="assets/images/payer.svg">
        <h1 class="pt-3 pb-2 m-auto"> When you expect this payment by payer?</h1>
        <!-- <input type="date" class="mt-2" [(ngModel)]="inputDate" placeholder="Choose date">   -->
        <div class="locateyourdata main_comp py-0 pb-0 col-lg-6 mt-0 datepicker">
            <!-- <span >Project start date</span> -->
            <!-- <input type="date" formControlName="project_start_date"> -->
            
            <mat-form-field appearance="fill" style="width:100%" class="mt-2">
                <!-- <img class="calender" src="assets/images/calendar-2.png" alt=""> -->
                <input matInput (click)="startDate.open()" readonly
                   formControlName="project_start_date"
                     [matDatepicker]="startDate "
                    placeholder="Enter Date" [(ngModel)]="project_start_date">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate>
                    
                </mat-datepicker>
               
            </mat-form-field>
     <small *ngIf="submitted && myForm.controls['project_start_date'].invalid " class="alert px-0 select-box-alert">
                <small *ngIf="submitted && myForm.controls['project_start_date'].errors.required">
                    Expected date of payment is required
                </small>
            </small>
            <!-- <mat-form-field appearance="fill" class="w-100" class="mt-2">
                <input matInput [matDatepicker]="startDate" placeholder="Select"
                    formControlName="project_start_date" (click)="startDate.open()" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker">
    
                </mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate>
            </mat-form-field> -->
    
            
            <!-- <small *ngIf="submitted && projectForm.controls['project_start_date'].invalid " class="alert px-0 select-box-alert">
                <small *ngIf="submitted && projectForm.controls['project_start_date'].errors.required">
                    Project start date is required
                </small>
            </small> -->
        </div>
    </div>
</form>

<div class="modal-footer d-flex justify-content-between mt-3">
    <button type="button" class="btn report me-2" (click)="close()">Cancel</button>
    <button type="button" class="btn cancel ms-2 mt-md-0 mt-3" (click)="submit()" >Submit</button>
</div>
