import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      let control = formGroup.controls[controlName];
      let matchingControl = formGroup.controls[matchingControlName]
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmPasswordValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  export function NewPasswordValidator(oldPasswordControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const oldPassword = oldPasswordControl.value;
    const newPassword = control.value;

    if (newPassword === oldPassword) {
      return { newPasswordSameAsOld: true };
    }

    return null;
  };
}

  
