import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouteConstant } from 'src/app/core/_constants/route.constant';

@Component({
  selector: 'app-addpost',
  templateUrl: './addpost.component.html',
  styleUrls: ['./addpost.component.scss']
})
export class AddpostComponent implements OnInit {

  open: any;
  currentUrl: any;
  profileTab: any;
  homeTab: any;
  RouteConstant:any = RouteConstant
  addPost:any = '/'  +RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.createPost
  selectRequirement:any ='/'  +RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.selectRequirement
  addProject:any = '/'  +RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.addProject
  addFeaturedProduct:any = '/'  +RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.addFeaturedProduct
  addCapacity:any  = '/'  +RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.addCapacity
  constructor( 
    private route: Router,
    public dialogRef: MatDialogRef<AddpostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit(): void {
    // this.currentUrl = this.route.url;

    // if(this.currentUrl == '/main/profile/about') {
    //   this.profileTab = true;
    // }

    // else if(this.currentUrl == '/main/profile/instruments') {
    //   this.profileTab = true;
    // }

    // else if(this.currentUrl == '/main/profile/products') {
    //   this.profileTab = true;
    // }

    // else if(this.currentUrl == '/main/profile/projects') {
    //   this.profileTab = true;
    // }

    // else {
    //   this.homeTab = true;
    // }
  }

  // routeTocreatepost() {
  //   this.route.navigate(['/main/profile/create-new-post'], {queryParams:{type: 'new'}})
  // }
}
