import { environment } from "src/environments/environment";

export const API_ROUTES={
    Account:{
        login:`${environment.baseURl}/v1/company/login`,
        //modified
        refresh_token: `${environment.baseURl}/v1/company/refresh-token`,

        signup:`${environment.baseURl}/v2/company/signup`,
        otpSendPhone:`${environment.baseURl}/v1/company/otp/send`,
        otpSendPhoneSignUp:`${environment.baseURl}/v1/company/signup/otp/send`,
        chooseInterest:`${environment.baseURl}/v1/company/add-interest`,
        verifyOTP:`${environment.baseURl}/v2/company/otp/verify`,
        getIndustry:`${environment.baseURl}/v1/company/industry/get`,
        getCategory:`${environment.baseURl}/v1/company/get/category/category1`,
        getsubCategory:`${environment.baseURl}/v1/company/get/category/category2`,
        industriesserve:`${environment.baseURl}/v1/company/get/category/categoryL4`,
        subActivtiyL:`${environment.baseURl}/v1/company/get/category/categoryL5`,
        getInterest:`${environment.baseURl}/v1/company/interest/get`,
        loginOtp:`${environment.baseURl}/v1/company/login/otp`,
        forgotPasword:`${environment.baseURl}/v1/company/forget-password`,
        resetPassword:`${environment.baseURl}/v1/company/reset-password`,
        editPhoneOrEmail: `${environment.baseURl}/v2/company/edit-phone-email`,
        socialLogin: `${environment.baseURl}/v1/company/login/social`,
        gstVerification: `${environment.baseURl}/v1/company/verify-gst`,
        bannerList: `${environment.baseURl}/v1/company/banner/list`,
        updateMobile: `${environment.baseURl}/v1/company/update-mobile-no`,
        getNotification: `${environment.baseURl}/v1/company/get/notification`,
        LinkedIn:{
            getAccessToken:`${environment.baseURl}/v1/company/linked-in`,
            getUserDetail: `${environment.baseURl}/v1/company/linkedIn-user-details`,
            getEmail: `${environment.baseURl}/v1/company/linkedIn-userEmail-details`
        },
        logout: `${environment.baseURl}/v1/company/logout`,
        createPassword: `${environment.baseURl}/v1/company/update/social-password`
    },
    More:{
        moreDetails:`${environment.baseURl}/v1/company/get/profile`,
        editProfile:`${environment.baseURl}/v1/company/update/profile`,
        weightage: `${environment.baseURl}/v1/company/profile-weightage`,
        weightageCalculation: `${environment.baseURl}/v1/company/profile-weightage-calcualtion`,
        changepic:`${environment.baseURl}/v1/company/update/picture`,
        changePassword:`${environment.baseURl}/v1/company/update-password`,
        addUser:`${environment.baseURl}/v1/company/user/register`,
        userList:`${environment.baseURl}/v1/company/get/user/list`,
        userDetails:`${environment.baseURl}/v1/company/get/user/detail`,
        userDelete:`${environment.baseURl}/v1/company/deactivate/user`,
        getInterest:`${environment.baseURl}/v1/company/interest/get`,
        selectedInterest: `${environment.baseURl}/v1/company/interest/getById`,
        updatePicture: `${environment.baseURl}/v1/company/update/picture`,
        updateInterest: `${environment.baseURl}/v1/company/update/Interest`,
        notification: `${environment.baseURl}/v1/company//enable-notification`,
        deleteAccount: `${environment.baseURl}/v1/company/account/Deactivate`,
        updateCustomInterest: `${environment.baseURl}/v1/company/update/custom-interest`

    },
    Post:{
        createPost:`${environment.baseURl}/v1/company/createPost`,
        createPostById: `${environment.baseURl}/v1/company/post/list/getById`,
        updatePost:`${environment.baseURl}/v1/company/post/update`,
        deletePost:`${environment.baseURl}/v1/company/post/delete`,
        myPost:`${environment.baseURl}/v1/company/post/list`,
        likePost:`${environment.baseURl}/v1/company/post/like`,
        postDetail:`${environment.baseURl}/v1/company/post/list/detail`,
        postComment:`${environment.baseURl}/v1/company/post/comment`,
        replyComment:`${environment.baseURl}/v1/company/post/comment/reply`,
        sharepost:`${environment.baseURl}/v1/company/post/share`,
        postCommentList:`${environment.baseURl}/v1/company/comment/list`,
        likeComment:`${environment.baseURl}/v1/company/post/comment/like`,
        editComment: `${environment.baseURl}/v1/company/post/comment-edit`,
        deleteComment: `${environment.baseURl}/v1/company/comment/delete`,
        stateCity: `${environment.baseURl}/v1/company/city-state`,
        postDetailGuest: `${environment.baseURl}/v1/company/guest-user/post/detail`
    },
    Home:{
        homeListing:`${environment.baseURl}/v1/company/home/list`,
        externalShareLink: `${environment.baseURl}/v1/company/generate-link`,
        likeList: `${environment.baseURl}/v1/company/post/like-list`,
        homeListingGuest: `${environment.baseURl}/v1/company/guest-user/home/list`,
        addClick: `${environment.baseURl}/v1/company/ads-banner-click`,
        addReport: `${environment.baseURl}/v1/company/report-ads`,
        notificationCount: `${environment.baseURl}/v1/company/notification/count`
    },
    chat:{
        intrestedUserList:`${environment.baseURl}/v1/company/interested-user`,
        getRoomId:`${environment.baseURl}/v1/company/chat-rooms`,
        chatInitiate: `${environment.baseURl}/v1/company/chat-initiate`
    },
    PurchaseOrder:{
        getCompanyList:`${environment.baseURl}/v1/company/get/company-detail`,
        getBusinessActivities:`${environment.baseURl}/v1/company/industry/get`,
        getIndustries:`${environment.baseURl}/v1/company/get/category/category1`,
        getProductSubCategoryList:`${environment.baseURl}/v1/company/get/category/category2`,
        getProductTypeList:`${environment.baseURl}/v1/company/get/category/category3`,
        getProductSubTypeList:`${environment.baseURl}/v1/company/get/category/category4`,
        getBrandList:`${environment.baseURl}/v1/company/product/brand`,
        createOrderForPurchase:`${environment.baseURl}/v1/company/create/purchase-order`,
        OtherOrderList:`${environment.baseURl}/v1/company/get/purchase-order-other-list`,
        PurchaseOrderList:`${environment.baseURl}/v1/company/get/purchase-order-list`,
        PurchaseOrderDetail:`${environment.baseURl}/v1/company/get/purchase-order-detail`,
        deletePurchaseOrder:`${environment.baseURl}/v1/company/delete/purchase`,
        rejectPurchaseOrder:`${environment.baseURl}/v1/company/rejected-order`,
        acceptPurchaseOrder:`${environment.baseURl}/v1/company/accepted-order`,
        updatePurchaseOrder:`${environment.baseURl}/v1/company/update/purchase`,
        getVersionList:`${environment.baseURl}/v1/company/get/version-list`,
        getVersion1detail:`${environment.baseURl}/v1/company/get/version1detail`,
        uploadChallan:`${environment.baseURl}/v1/company/create/challan-invoice`,
        getInvoice:`${environment.baseURl}/v1/company/get/challan-invoice`,
        dispatch:`${environment.baseURl}/v1/company/is-delay`,
        Milestonelist:`${environment.baseURl}/v1/company/get/deliver-milestone-list`,
        versionList: `${environment.baseURl}/v1/company/get-order-version`
    },
    Projects:{
        createProjects:`${environment.baseURl}/v1/company/project/create`,
        updateProjects:`${environment.baseURl}/v1/company/project/update`,
        projectsDetail:`${environment.baseURl}/v1/company/project/details`,
        projectsList:`${environment.baseURl}/v1/company/project/list`,
        deleteProject:`${environment.baseURl}/v1/company/project/delete`,
        addTestimonials: `${environment.baseURl}/v1/company//add/testimonials`
    },
    Machine:{
        machineList:`${environment.baseURl}/v1/company/machine_instrument/list`,
        createMachine:`${environment.baseURl}/v1/company/machine_instrument/create`,
        machineDetail:`${environment.baseURl}/v1/company/machine_instrument/details`,
        updateMachine:`${environment.baseURl}/v1/company/machine_instrument/update`,
        // getMachine:`${environment.baseURl}/v1/company/machiene/details`,
        deleteMachine:`${environment.baseURl}/v1/company/machine_instrument/delete`,
        instrumentList:`${environment.baseURl}/v1/company/machine_instrument/list`
   
},
    MyRequirements: {
        industryList:`${environment.baseURl}/v1/company/industry/get`,
        industryCategory1:`${environment.baseURl}/v1/company/get/category/category1`,
        industryCategory2: `${environment.baseURl}/v1/company/get/category/category2`,
        industryCategory3: `${environment.baseURl}/v1/company/get/category/category3`,
        createRequirement: `${environment.baseURl}/v1/company/requirement/create`,
        getRequirementDetail: `${environment.baseURl}/v1/company/requirement/getDetail`,
        getRequirementDetailforGuest: `${environment.baseURl}/v1/company/guest-user/req/detail`,
        updateRequirement: `${environment.baseURl}/v1/company/requirement/update`,
        getAllRequirementList: `${environment.baseURl}/v1/company/requirement/getListById`,
        deleteRequirement: `${environment.baseURl}/v1/company/requirement/delete`,
        interestedUsers: `${environment.baseURl}/v1/company/interested-user`,
        saveRequirement: `${environment.baseURl}/v1/company/save/requirement`,
        showinterest:`${environment.baseURl}/v1/company/requirement/like/unlike`,
        report:`${environment.baseURl}/v1/company/report`,
        SaveList: `${environment.baseURl}/v1/company/requirement/saveList`,
        unSave: `${environment.baseURl}/v1/company/unsave/requirement`,
        proposal: `${environment.baseURl}/v1/company/get-proposal`,
        askForProposal: `${environment.baseURl}/v1/company/ask-for-proposal`,
        sendProposal: `${environment.baseURl}/v1/company/add/proposal`,
        rawMaterialC1 : `${environment.baseURl}/v1/company/get/raw/material-C1-List`,
        rawMaterialC2 : `${environment.baseURl}/v1/company/get/raw/material-C2-List`,
        machineIndustryList : `${environment.baseURl}/v1/company/get/industry-machine-list`,
        machineNameList : `${environment.baseURl}/v1/company/get/req-machiene-list`
    },

FeatureProduct:{
    addProduct:`${environment.baseURl}/v1/company/featuredproduct/create`,
    productList:`${environment.baseURl}/v1/company/product/list`,
    productDetails:`${environment.baseURl}/v1/company/product/details`,
    productupdate:`${environment.baseURl}/v1/company/product/update`,
    deleteProduct:`${environment.baseURl}/v1/company/product/delete`
},
Marketplace:{
    addProduct:`${environment.baseURl}/v1/company/product/create`,
    businessActivity:`${environment.baseURl}/v1/company/industry/get`,
    catagory_1:`${environment.baseURl}/v1/company/get/category/category1`,
    catagory_2:`${environment.baseURl}/v1/company/get/category/category2`,
    catagory_3:`${environment.baseURl}/v1/company/get/category/category3`,
    catagory_4:`${environment.baseURl}/v1/company/get/category/category4`,
    brand:`${environment.baseURl}/v1/company/product/brand`,
    mymarketproductList:`${environment.baseURl}/v1/company/product/getList`,
    updateProduct:`${environment.baseURl}/v1/company/update/product`,
    productDetails:`${environment.baseURl}/v1/company/product/getDetails`,
    productDetailsGuest: `${environment.baseURl}/v1/company/guest-user/product/detail`,
    deleteProduct:`${environment.baseURl}/v1/company/delete/product`,
    shareToMarketPlace: `${environment.baseURl}/v1/company/product/share-to-marketplace`,
    marketPlaceList: `${environment.baseURl}/v3/company/product/market-place/list`,
    marketPlaceList2: `${environment.baseURl}/v1/company/product/market-place/list`,
    myMarketPlaceList: `${environment.baseURl}/v1/company/product/my-market-place/list`,
    showInterest: `${environment.baseURl}/v1/company/product/interest`,
    interestedUser: `${environment.baseURl}/v1/company/product/interested-user`,
    marketPlaceGuestList : `${environment.baseURl}/v3/company/guest-user/market-place/list`,
    marketPlaceGuestList2 : `${environment.baseURl}/v1/company/guest-user/market-place/list`,
    popularProducts: `${environment.baseURl}/v1/company/product/similar-product`,
    industry: `${environment.baseURl}/v1/company/industry/get`,
    getCategory : `${environment.baseURl}/v1/company/get/category/category1`,
    category: `${environment.baseURl}/v1/company/get/category/category2`,
    productType: `${environment.baseURl}/v1/company/get/category/category3`,
    catagoryType4: `${environment.baseURl}/v1/company/get/category/category4`
},
customers:{
    addcustomer:`${environment.baseURl}/v1/company/create/customerInfo`,
    blockedCustomers: `${environment.baseURl}/v1/company/customer/BlockedList`,
    unblockCustomer: `${environment.baseURl}/v1/company/customer/UnBlocked`,
    customerDetail: `${environment.baseURl}/v1/company/customer/Details`,
    updateCustomer:`${environment.baseURl}/v1/company/update/customer`,
    customerDetails:`${environment.baseURl}/v1/company/customer/Details`,
    getState:`${environment.baseURl}/v1/company/city-state`,
    getBuyer:`${environment.baseURl}/v1/company/customer/buyerSeller/List`,
    blockCustomer: `${environment.baseURl}/v1/company/customer/Blocked`,
    paymentDetails:`${environment.baseURl}/v1/company/customer/payment-list`,
    orderDetails: `${environment.baseURl}/v1/company/customer/order-list`,
    getSeller:`${environment.baseURl}/v1/company/customer/buyerSeller/List`,
    customerList: `${environment.baseURl}/v1/company/customer/list`
},
Transactions:{
    create:`${environment.baseURl}/v1/company/create/transaction`,
    transactionList: `${environment.baseURl}/v1/company/get/transaction-list`,
    deleteTransaction: `${environment.baseURl}/v1/company/delete/transaction`,
    detailTransaction: `${environment.baseURl}/v1/company/get/transaction-detail`,
    updateTransaction: `${environment.baseURl}/v1/company/update/transaction`,
    stats: `${environment.baseURl}/v1/company/get/stasticsList`,
    addExpense:`${environment.baseURl}/v1/company/create/expenses`,
    updateExpense:`${environment.baseURl}/v1/company/update/expenses`,
    expenseDetails:`${environment.baseURl}/v1/company/get/expenses-detail`,
    expenseList:`${environment.baseURl}/v1/company/get/expenses-list`,
    deleteExpense:`${environment.baseURl}/v1/company/delete/expenses`,
},

SalesOrder: {
    salesOrderList:`${environment.baseURl}/v1/company/get/sales-order-list`,
    delete: `${environment.baseURl}/v1/company/delete/sales`,
    getCompanyList:`${environment.baseURl}/v1/company/get/company-detail`,
    getBusinessActivities:`${environment.baseURl}/v1/company/industry/get`,
    getIndustries:`${environment.baseURl}/v1/company/get/category/category1`,
    getProductSubCategoryList:`${environment.baseURl}/v1/company/get/category/category2`,
    getProductTypeList:`${environment.baseURl}/v1/company/get/category/category3`,
    getProductSubTypeList:`${environment.baseURl}/v1/company/get/category/category4`,
    getBrandList:`${environment.baseURl}/v1/company/product/brand`,
    createOrderForSales:`${environment.baseURl}/v1/company/create/sales-order`,
    OtherOrderList:`${environment.baseURl}/v1/company/get/sales-order-other-list`,
    SalesOrderList:`${environment.baseURl}/v1/company/get/sales-order-list`,
    SalesOrderDetail:`${environment.baseURl}/v1/company/get/sales-order-detail`,
    PurchaseOrderDetail:`${environment.baseURl}/v1/company/get/purchase-order-detail`,
    customerPurchaseList:`${environment.baseURl}/v1/company/get/company-wise-purchase-list`,
    updateSalesOrder:`${environment.baseURl}/v1/company/update/sales-order`,
    requeustToEdit:`${environment.baseURl}/v1/company/request-to-edit`,
    acceptOrder:`${environment.baseURl}/v1/company/accepted-order`,
    deletePurchaseOrder: `${environment.baseURl}/v1/company/delete/purchase`,
},
ActivityLogs: {
    logs:`${environment.baseURl}/v1/company/get/activity-log-list`,
    activityStatus: `${environment.baseURl}/v1/company/check-activity-log`
},
Dashboard: {
    logs:`${environment.baseURl}/v1/company/get/stasticsList`,
},
MarketPlaceFilters:{
    filters:`${environment.baseURl}/v1/company/get/category`,
    getStaticIndustry: `${environment.baseURl}/v1/company/category/category1-list `,
    marketPlaceFilters: `${environment.baseURl}/v1/company/marketplace-filters`
}

}
