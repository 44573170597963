<div class="modal-header pb-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
        <img src="assets/images/arrow_close.svg">
    </button>
</div>
<div class="modal-body pt-0">
    <div class="d-flex align-items-center justify-content-center ">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item d-flex align-items-center " role="presentation">
                <button class="nav-link active " id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
                    role="tab" aria-controls="home" aria-selected="true">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7H21Z" fill="#768DA9" />
                        <path d="M3 7H21" stroke="#768DA9" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M6 12H18" stroke="#768DA9" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M10 17H14" stroke="#768DA9" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                    Sort by</button>
            </li>
            <li class="nav-item d-flex align-items-center" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                    role="tab" aria-controls="profile" aria-selected="false">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.40002 2.09998H18.6C19.7 2.09998 20.6 2.99998 20.6 4.09998V6.29998C20.6 7.09998 20.1 8.09998 19.6 8.59998L15.3 12.4C14.7 12.9 14.3 13.9 14.3 14.7V19C14.3 19.6 13.9 20.4 13.4 20.7L12 21.6C10.7 22.4 8.90002 21.5 8.90002 19.9V14.6C8.90002 13.9 8.50002 13 8.10002 12.5L4.30002 8.49998C3.80002 7.99998 3.40002 7.09998 3.40002 6.49998V4.19998C3.40002 2.99998 4.30002 2.09998 5.40002 2.09998Z"
                            fill="#5B6D8E" />
                    </svg>Filter by</button>

            </li>
        </ul>
    </div>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="d-flex justify-content-between align-items-center mt-md-4 mt-2" *ngFor="let sort of sort">
                <label class="container" (change)="onCheckboxChange($event)">
                    <input type="checkbox" [value]="sort.label" [checked]="sort.isSelected">
                    <span class="checkmark"></span>
                    {{sort.label}}
                </label>
            </div>
            <div>
                <button class="btn apply-btn mt-4" (click)="sortBy()">
                    Apply <img src="assets/images/login.svg">
                </button>
            </div>
        </div>
        <div [formGroup]="filterForm" class="tab-pane fade " id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="mt-3 d-flex flex-column phonedetails">
                <mat-form-field appearance="fill" class="">
                    <mat-select formControlName="industry" placeholder="Select business activity" max="999999">
                        <mat-option value="0" selected>Select business activity</mat-option>
                        <!-- <option value="" disabled selected hidden>Select Industry</option> -->
                        <mat-option *ngFor="let business of business" [value]="business.industry_name">
                            {{business.industry_name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="mt-3 d-flex flex-column phonedetails">
                <mat-form-field appearance="fill" class="">
                    <mat-select formControlName="category1" placeholder="Select industry">
                        <mat-option value="0" selected>Select industry</mat-option>
                        <!-- <option value="" disabled selected hidden>Select Industry</option> -->
                        <mat-option *ngFor="let industry of industry" [value]="industry.title">
                            {{industry.title}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="mt-3 d-flex flex-column phonedetails">
                <mat-form-field appearance="fill" class="">
                    <mat-select formControlName="category2" placeholder="Select sub category">
                        <!-- <option value="" disabled selected hidden>Select Industry</option> -->
                        <mat-option value="0" selected>Select sub category</mat-option>
                        <mat-option *ngFor="let subCatagory of subCatagory" [value]="subCatagory.title">
                            {{subCatagory.title}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="mt-3 d-flex flex-column phonedetails">
                <mat-form-field appearance="fill" class="">
                    <mat-select formControlName="category3" placeholder="Select Product type">
                        <!-- <option value="" disabled selected hidden>Select Industry</option> -->
                        <mat-option value="0" selected>Select product type</mat-option>
                        <mat-option *ngFor="let producttype of producttype" [value]="producttype.title">
                            {{producttype.title}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="mt-3 d-flex flex-column phonedetails">
                <mat-form-field appearance="fill" class="">
                    <mat-select formControlName="category4" placeholder="Select Product sub type">
                        <!-- <option value="" disabled selected hidden>Select Industry</option> -->
                        <mat-option value="0" selected>Select product sub type</mat-option>
                        <mat-option *ngFor="let substype of substype" [value]="substype.title">
                            {{substype.title}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="mt-3 d-flex flex-column phonedetails">
                <mat-form-field appearance="fill" class="">
                    <mat-select formControlName="brand" placeholder="Select brand">
                        <!-- <option value="" disabled selected hidden>Select Industry</option> -->
                        <mat-option value="0" selected>Select brand</mat-option>
                        <mat-option *ngFor="let productBrand of productBrand" [value]="productBrand.brand_name">
                            {{productBrand.brand_name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div>
                <button (click)="filter()" class="btn apply-btn mt-4">
                    Apply <img src="assets/images/login.svg">
                </button>
            </div>
        </div>
    </div>
</div>