<div class="modal-header pb-0">
    <img src="assets/images/close.png" alt="" class="cursor" [mat-dialog-close]="false">
</div>
<div class="modal-body pt-0">
    <form  [formGroup]="myForm">
        <label>{{data?.heading}}</label>
        <div class="d-flex justify-content-between flex-column phonedetails">
            <mat-form-field appearance="fill">
                <mat-select placeholder="Select milestone" formControlName="mile_delivered">
                    <mat-option *ngFor="let item of milestoneList" [value]="item.order_id">{{item.milestone_name}} {{' - '}}{{item.created_at | date:'dd/MM/yyyy HH:mm'}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="submitted && myForm.controls['mile_delivered'].errors">
                <small  *ngIf="myForm.controls['mile_delivered'].errors.required">Please select milestone</small>
            </div>
        </div>
    </form>
   
</div>
<div class="modal-footer d-flex justify-content-between pt-4">
    <button type="button" class="btn cancel" (click)="onSubmit()" >{{data?.submit}}</button>
    
</div>
