import { Location, ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, debounceTime, takeUntil } from 'rxjs';
import { API_ROUTES } from '../core/_constants/api-route.constant';
import { ViewImageComponent } from '../shared/dialogs/view-image/view-image.component';
import { ApiServicesService } from './apiServices/api-services.service';
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
import { Userx } from '../user.template';
import { EditPopupComponent } from '../shared/dialogs/edit-popup/edit-popup.component';
import { Router } from '@angular/router';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { PostPublishComponent } from '../shared/dialogs/post-publish/post-publish.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  userList: any = [];
  authUserId: any;
  user: Userx;
  message = {
    attachment: "",
    isDeleted: false,
    messageId: "",
    messageStatus: "sent",
    messageText: "Hi",
    messageTimestamp: 1662978859386,
    messageType: 1,
    received: true,
    recieverId: "b598863d-eed8-4db4-bbd9-2b59d18fe24f",
    senderId: "9da8f29f-38cc-48e4-88b3-0bf32c3066c6",
    sent: true,
    thumbnail: ""
  }

  constructor(private location: Location, private dialog: MatDialog, private service: ApiServicesService,
    private db: AngularFireDatabase, private router: Router, private viewportScroller: ViewportScroller,
    private afAuth: AngularFireAuth,
    private storageService: LocalStorageProvider) { }



  private socialMandatePopupSubject = new Subject<void>();

  socialMandatePopup$ = this.socialMandatePopupSubject.asObservable();

  triggerSocialMandatePopup() {
    this.socialMandatePopupSubject.next();
  }



  private openPopup = new BehaviorSubject<any>(null);
  data$ = this.openPopup.asObservable();

  sendData(data: any) {
    this.openPopup.next(data);
  }
  getData() {
    return this.openPopup.asObservable();
  }

  goBack() {
    this.location.back();
  }

  viewImage(url: any) {
    //if(type){
    const dialogRef = this.dialog.open(ViewImageComponent, {
      width: '100%',
      height: '80vh',
      panelClass: 'resetPassword',
      data: {
        img: url
      }
    })
    //}
  }

  async getState() {
    let res = await this.service.get({}, `${API_ROUTES.Post.stateCity}`)
    return res;
  }

  async getCity(value: any) {
    let param = {
      state: value
    }
    let res = await this.service.get(param, `${API_ROUTES.Post.stateCity}`)
    return res;
  }

  setUserInFirebase(data: any) {
    this.authUserId = this.storageService.getItem('companyId')
    this.db.object('user').query.once('value', (res => {
      //  
      if (res.val()) {
        this.userList = Object.values(res.val())
        //  
        let obj = this.userList.find((el: any) => el.userId == this.authUserId);
        //if(obj){

        // }
        //else {
        const userRef = this.db.object<Userx>(`${'user/' + this.authUserId}`);
        // this.user.email = data.Auth.email;
        // this.user.name = data.company_name;
        // this.user.userId = data.auth_id;
        // this.user.userImage = data.profile_pic

        let payload: any = {
          badgeCount: 0,
          email: data.Auth.email,
          isOnline: false,
          name: data.company_name,
          phone: data.Auth.phone_no,
          status: 1,
          userId: data.id,
          userImage: data.profile_pic ? data.profile_pic : ''
        }
        userRef.update(payload);

        //}
      }


    }))
    //     this.db.list('user').snapshotChanges().subscribe(res => {
    //       res.forEach(doc => {
    //       this.userList.push(doc.payload.val());
    //   }
    //   );

  }

  signup(email: any, password: any) {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        // ..
      });
  }

  signIn(email: any, password: any) {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;

        // ...
      })
      .catch((error) => {

      });
  }

  isSpaceKey(evt: any) {
    //  
    //var e = evt || window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode == 32) {
      return false
    }

    return true;

  }

  guestUserPopup() {
    const screenWidth = window.innerWidth;
    let isSmallScreen = false;
    if (screenWidth <= 1440) {
      isSmallScreen = true;
    }
    const dialogRef = this.dialog.open(EditPopupComponent, {
      maxHeight: '100vh',
      width: isSmallScreen ? '350px' : '465px',
      data: {
        // img:'assets/images/Delete.png',
        heading: 'Please login for performing this action',
        // para:'Lorem Ipsum is simply dummy text of the printing text of the printing. ',
        report: 'Ok',
        cancel: 'Cancel'
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/login']);
      }
    });
  }

  filterImagesVideosPdf(data: any) {
    let array = data.reduce((acc: any, element: any) => {
      if (element.media_type.includes("image")) {
        acc['img'].push(element);
      }
      else if (element.media_type.includes("video")) {
        acc?.video?.push(element);
      }
      else {
        acc['pdf'].push(element);
      }
      return acc;
    }, { img: [], video: [], pdf: [] })
    return array;

  }

  filesArranging(data: any) {
    let images: any = [];
    let videos: any = [];
    let pdf: any = [];
    data.forEach((element: any) => {
      let media = {
        'media_type': element.media_type,
        'url': element.url
      }
      if (element.media_type.toLowerCase().includes('image')) {
        images.push(media)
      }
      else if (element.media_type.toLowerCase().includes('video')) {
        videos.push(media)
      }
      else {
        pdf.push(media)
      }
    })
    return { images, videos, pdf }

  }

  slidesArranging(data: any, id: any) {
    let slides: any = []
    data.Media.forEach((item: any, index: any) => {
      let slide = {
        url: item.url,
        type: '',
        thumbnail: item.thumbnail_url,
        id: id + index,
        media_type: item.media_type,
        download: item.url
      }
      if (item.media_type.toLowerCase().includes('image')) {
        slide.type = 'image';
        slides.push(slide);
      }
      else if (item.media_type.toLowerCase().includes('video')) {
        slide.type = 'video';
        slides.push(slide);
      }
      else {
        slide.type = 'pdf';
        slide.url = 'assets/images/homePdf.png';
        slides.push(slide);
      }
    })
    return slides;
  }

  async getCountry() {
    let url = 'assets/localJson/country.json';
    let res: any = await this.service.get({}, url)
    return res
  }

  businessTabPopup() {
    const isSmallScreen = this.viewportScroller.getScrollPosition()[0] <= 1440;

    const dialogRef = this.dialog.open(PostPublishComponent, {
      maxHeight: '100vh',
      width: isSmallScreen ? '350px' : '465px',
      panelClass: 'products',
      data: {
        // img: 'assets/images/Success.png',
        heading: 'Business Tab is Coming Soon',
        // title: 'Please check your inbox and click in the recieved link to reset a password',
        btn: 'Okay'
      }

    })
  }

  toUpperCase(form: any, ev: any, control: any) {
    form.patchValue({ [control]: ev.target.value.toUpperCase() })
  }

  deleteFilterData() {
    if (localStorage.getItem("marketPlaceFilter")) {
      localStorage.removeItem("marketPlaceFilter")
    }
    if (localStorage.getItem("marketPlaceSort")) {
      localStorage.removeItem("marketPlaceSort")
    }
  }

  resfreshFlag: Subject<boolean> = new Subject<boolean>();
  getRefreshData() {
    this.service.refreshToken().pipe(debounceTime(5000), takeUntil(this.resfreshFlag)).subscribe((res) => {
      let new_token = res?.result?.new_token;
      let new_refresh_token = res?.result?.new_refresh_token;
      this.storageService.setItem('access_token', new_token)
      this.storageService.setItem('refresh_token', new_refresh_token)

      // setTimeout(() => {
      this.resfreshFlag.next(true);
      // }, 0)
      // localStorage.setItem('access_token',)
    })
  }

  getRefreshFlag() {
    return this.resfreshFlag.asObservable();
  }

  // this.common_service.getRefreshData.subscribe(res => {
  //   if(res) {
  //     this.ngOnInit()
  //   }
  // })

  returnErrorMessage = (data: any) => {

    if (data?.message === "Validation error.") {
      return data?.result?.error?.replace(/["_]/g, ' ');
    }
    if (data?.message) {
      return data?.message?.replace(/["_]/g, ' ');
    }
    if (data?.error) {
      return data?.error?.replace(/["_]/g, ' ');
    }
    // return 'Something went wrong please try again later';
  };
  profileData: any;
  logout() {
    this.profileData = localStorage.getItem("profileData");
    if (this.profileData && (this.profileData)) {
      this.signOut();
    }
    else {
      // this.router.navigate([''])
      this.logoutAPI();
    }

  }
  async signOut() {
    await this.afAuth.signOut();
    this.logoutAPI();
  }

  logoutAPI() {
    let params = {
      device_id: sessionStorage.getItem('device_Id')?.toString()
    }
    this.service.delete(params, `${API_ROUTES.Account.logout}`).pipe().subscribe((res) => {
      if (res.success) {
        localStorage.removeItem('access_token');
        // localStorage.removeItem('isLoggedIn');
        this.storageService.clearAll();
        window.location.reload();
        this.router.navigate([''])
      }
    }, (error) => {
      localStorage.removeItem('access_token');
      this.storageService.clearAll();
      window.location.reload();
      this.router.navigate([''])
    })
  }
}
