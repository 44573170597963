export const RouteConstant = {
    Login:'login',
    
    
    
    // otpVarification:'otp-varification',
    
    
    // HomeExpanded:'home-expanded',
    // postDetail:'post-detail',
    // requirementDetail:'requirement-detail',
    // marketplace:'marketplace',
    // addNewProduct:'add-new-product',
    // requestRaftaarrVerification:'request-raftaarr-verification',
    // more:'more',
    
    // myPost:'myPost',
    // newFeature:'newFeature',
    // newMachine:'newMachine',
    // newProjects:'newProjects',
    // myMarketplace:'myMarketplace',
    // aboutProfile:'aboutProfile',
    
    
    
    
    
    
    
    // ads:'ads',
    
    
    
    // profileAbout:'/main/profile/about',
    // ProfileProjects: '/main/profile/projects',
    // ProfileFeaturedProducts: '/main/profile/products',
    // ProfileCapacity: '/main/profile/instruments',
    // homeScreen: '/main/home',
    // addProject:'/main/profile/newProjects',
    // addFeaturedProduct: '/main/profile/newFeature',
    // addCapacity: '/main/profile/newMachine',
    // projectDetail: '/main/profile/profile-project-detail',
    // featuredProductDetail: '/main/profile/product-details',
    // addPost: '/main/home/create-new-post',
    // selectRequirement: '/main/home/add-new-requirementtab',
    

    // home module
    
    

    // marketPlace module
    

    // profile module
    // profileAddPost: '/main/profile/create-new-post',
    // profileSelectRequirement: '/main/profile/add-new-requirementtab',
    // profileOrderRequirement: '/main/profile/manufacturing-o-r',
    // profileHrRequirement: '/main/profile/hr-requirement', 
    // profileMachineRequirement: '/main/profile/machine-requirement',
    // profileFinanceRequirement: '/main/profile/finance',
    // profileRawMaterialRequirement: '/main/profile/raw-material',
    // profileSpecialRequirement: '/main/profile/other',


    // more module
    
    // editProfile: '/main/more/edit-profile',
    // savedRequirements: '/main/more/saved',
    // moreInterests: '/main/more/interests',
    // moreSettings: '/main/more/settings',
    // moreChangePassword: '/main/more/change-password',
    // // Settings
    // addUser: '/main/more/add-new-user',
    // userDetail: '/main/more/userdetail',

    //chat module
    


    // Business module
    




    // Routes
    layout:'layout',
    main: 'main',
    home:'home',
    PhoneOtp:'phone-otp',
    Signup:'signup',
    resetPassword:'reset-password',
    forgotPassword:'forgot-password',
    chooseInterest:'choose-interest',
    createPost: 'create-new-post',
    selectRequirement: 'add-new-requirementtab',
    addOrderRequirement: 'manufacturing-o-r',
    addHrRequirement: 'hr-requirement',
    addMachineRequirement: 'machine-requirement',
    addRawMaterialRequirement: 'raw-material',
    addFinanceRequirement: 'finance',
    addSpecialRequirement: 'other',
    requirementDetail: 'manufacturing-o-r_home',
    postDetailScreen: 'post-detail',
    myPostList: 'my-post-list',
    myRequirementList: 'manufacturing-o-r-list',
    myMarketPlaceList: 'myMarketplace',
    notificationList: 'hr-requirement-list',
    interestedUser: 'interest-user',
    proposalScreen: 'proposal-screen-comp',
    viewProposal: 'proposalscreen',
    chat: 'chat',
    chatList: 'chatList',
    chatScreen: 'chatHello',
    marketPlaceList: 'marketplace',
    addMarketPlaceProduct: 'add-new-product',
    editMarketPlaceProduct: 'editproduct',
    marketplaceProductDetail: 'grindingmachine',
    popularProducts: 'popular-product',
    newArrivals: 'new-arrivals',
    bestSellerProducts: 'best-seller-products',
    profile:'profile',
    about:'about',
    projectsList: 'projects',
    ProfileFeaturedProducts: 'products',
    ProfileCapacity: 'instruments',
    addProject:'newProjects',
    addFeaturedProduct: 'newFeature',
    addCapacity: 'newMachine',
    projectDetail: 'profile-project-detail',
    featuredProductDetail: 'product-details',
    moreProfile: 'more',
    editProfile: 'edit-profile',
    savedRequirements: 'saved',
    moreInterests: 'interests',
    moreSettings: 'settings',
    moreChangePassword: 'change-password',
    // Settings
    addUser: 'add-new-user',
    userDetail: 'userdetail',
    notificationSettings:'notification',
    business: 'business',
    businessDashboard:'businessDashboard',
    productCatalogue:'productCatalogue',
    addProductCatalogue: 'add-product-catalogue',
    productCatalogueDetail: 'product-catalogue-details',
    salesorder:'salesorder',
    // salesOrderList: 'salesorder',
    addSalesOrder: 'add-new-sales-order',
    // addSalesOrderScreen: '/business/salesorder/add-new-sales-order',
    editSalesOrder: 'edit-sales-order',
    // editSalesOrderScreen: '/business/salesorder/edit-sales-order',
    viewPurchaseOrderInSalesOrder : 'purchase-order',
    salesOrderDetail: 'order-detail-accept',
    transactions:'transactions',
    viewStats: 'view-statics',
    transactionsList: 'transaction-list',
    transactionsListDebit: 'debit',
    transactionsListCredit: 'credit',
    addTransaction: 'add-new-transaction',
    editTransaction: 'edit-transaction',
    expensesList: 'expenses',
    addExpense: 'add-new-expense',
    purchaseOrder:'purchase-order-request',
    // purchaseOrderList: 'purchase-order-request',
    ViewOrderPurchaseOrder: 'purchaseOrder',
    addPurchaseOrder: 'add-purchase-order',
    editPurchaseOrder: 'edit-purchase-order',
    purchaseOrderDetail: 'purchase-order-accept',
    activityLog:'activityLog',
    customers:'customers',
    customerListBuyer: 'buyer',
    customerListSeller: 'seller',
    addCustomer: 'add-client',
    customerDetail: 'customer-details',
    customerPaymentDetail: 'payment-details',
    customerOrderDetail: 'order-details',
    blockedCustomerList: 'blocked-customers',

    // help
    help:'help',
    
    
}   