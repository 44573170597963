<div class="modal-header pb-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
       <img src="assets/images/close.png" alt="">
    </button>
</div>
<div class="modal-body pt-0">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item d-flex align-items-center " role="presentation">
            <button class="nav-link active " id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M13.75 7.71156V13.0714C13.7386 14.1344 13.5784 14.6041 13.3168 14.8502C13.0463 15.1047 12.532 15.2516 11.415 15.2516H4.33502C3.19879 15.2516 2.68628 15.0985 2.41933 14.835C2.15469 14.5739 2 14.0736 2 12.9541V7.71156C2 6.70258 2.1264 6.19336 2.35029 5.91088C2.55179 5.65664 2.924 5.47925 3.77522 5.42845C3.95329 5.42104 4.1379 5.41406 4.33502 5.41406H11.415C12.5512 5.41406 13.0637 5.56717 13.3307 5.83059C13.5953 6.09172 13.75 6.59204 13.75 7.71156Z" fill="#768DA9" stroke="#768DA9"/> <path d="M16 5.0475V10.29C16 11.299 15.8736 11.8082 15.6497 12.0907C15.4956 12.2851 15.2417 12.4346 14.75 12.5169V7.71C14.75 6.59452 14.6197 5.69609 14.0331 5.11723C13.4487 4.54064 12.5437 4.4125 11.415 4.4125H4.33502C4.31327 4.4125 4.29169 4.41258 4.27028 4.41272C4.32225 3.70569 4.46862 3.35327 4.68324 3.15136C4.95372 2.8969 5.46795 2.75 6.58502 2.75H13.665C14.8012 2.75 15.3137 2.90311 15.5807 3.16652C15.8453 3.42766 16 3.92798 16 5.0475Z" fill="#768DA9" stroke="#768DA9"/> <path d="M5.21971 13.9219H3.92969C3.62219 13.9219 3.36719 13.6669 3.36719 13.3594C3.36719 13.0519 3.62219 12.7969 3.92969 12.7969H5.21971C5.52721 12.7969 5.78221 13.0519 5.78221 13.3594C5.78221 13.6669 5.53471 13.9219 5.21971 13.9219Z" fill="#768DA9"/> <path d="M9.41203 13.9219H6.83203C6.52453 13.9219 6.26953 13.6669 6.26953 13.3594C6.26953 13.0519 6.52453 12.7969 6.83203 12.7969H9.41203C9.71953 12.7969 9.97453 13.0519 9.97453 13.3594C9.97453 13.6669 9.72703 13.9219 9.41203 13.9219Z" fill="#768DA9"/> <path d="M14.25 8.89453H1.5V10.0195H14.25V8.89453Z" fill="#768DA9"/>
                </svg>
                Credit
            </button>
        </li>
        <li class="nav-item d-flex align-items-center" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M13.75 7.71156V13.0714C13.7386 14.1344 13.5784 14.6041 13.3168 14.8502C13.0463 15.1047 12.532 15.2516 11.415 15.2516H4.33502C3.19879 15.2516 2.68628 15.0985 2.41933 14.835C2.15469 14.5739 2 14.0736 2 12.9541V7.71156C2 6.70258 2.1264 6.19336 2.35029 5.91088C2.55179 5.65664 2.924 5.47925 3.77522 5.42845C3.95329 5.42104 4.1379 5.41406 4.33502 5.41406H11.415C12.5512 5.41406 13.0637 5.56717 13.3307 5.83059C13.5953 6.09172 13.75 6.59204 13.75 7.71156Z" fill="#768DA9" stroke="#768DA9"/>
                    <path d="M16 5.0475V10.29C16 11.299 15.8736 11.8082 15.6497 12.0907C15.4956 12.2851 15.2417 12.4346 14.75 12.5169V7.71C14.75 6.59452 14.6197 5.69609 14.0331 5.11723C13.4487 4.54064 12.5437 4.4125 11.415 4.4125H4.33502C4.31327 4.4125 4.29169 4.41258 4.27028 4.41272C4.32225 3.70569 4.46862 3.35327 4.68324 3.15136C4.95372 2.8969 5.46795 2.75 6.58502 2.75H13.665C14.8012 2.75 15.3137 2.90311 15.5807 3.16652C15.8453 3.42766 16 3.92798 16 5.0475Z" fill="#768DA9" stroke="#768DA9"/>
                    <path d="M5.21971 13.9219H3.92969C3.62219 13.9219 3.36719 13.6669 3.36719 13.3594C3.36719 13.0519 3.62219 12.7969 3.92969 12.7969H5.21971C5.52721 12.7969 5.78221 13.0519 5.78221 13.3594C5.78221 13.6669 5.53471 13.9219 5.21971 13.9219Z" fill="#768DA9"/>
                    <path d="M9.41203 13.9219H6.83203C6.52453 13.9219 6.26953 13.6669 6.26953 13.3594C6.26953 13.0519 6.52453 12.7969 6.83203 12.7969H9.41203C9.71953 12.7969 9.97453 13.0519 9.97453 13.3594C9.97453 13.6669 9.72703 13.9219 9.41203 13.9219Z" fill="#768DA9"/>
                    <path d="M14.25 8.89453H1.5V10.0195H14.25V8.89453Z" fill="#768DA9"/>
                </svg>
                Debit
            </button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <h3 class="mt-3">Filter by</h3>
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="d-flex justify-content-between align-items-center mt-3" *ngFor="let filter of filters">
                <label class="container ps-0">
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                    {{filter.label}}
                </label>
            </div>
            <div>
                <button class="btn apply-btn mt-4">
                    Apply <img src="assets/images/login.svg">
                </button>
            </div>
        </div>
        <div class="tab-pane fade " id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="d-flex justify-content-between align-items-center mt-3" *ngFor="let filter of filters">
                <label class="container ps-0">
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                    {{filter.label}}
                </label>
            </div>
            <div>
                <button class="btn apply-btn mt-4">
                    Apply <img src="assets/images/login.svg">
                </button>
            </div>
        </div>
    </div>
</div>

